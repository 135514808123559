import React, { Component } from "react";
import CropSpecialCoin from "./CropSpecialCoin";

export default class AddSpecialCoin extends Component {
  state = {
    cropping: false,
    year: null,
    denomination: null,
    description: null,
    image: null,
    created: null,
    updated: null,
    name: null,
    imageURL: null
  };

  yearRef = React.createRef();
  denominationRef = React.createRef();
  descriptionRef = React.createRef();
  imageRef = React.createRef();

  createCoin = e => {
    e.preventDefault();

    const date = Date.now();

    const year = this.yearRef.current.value;
    const denomination = this.denominationRef.current.value;
    const description = this.descriptionRef.current.value;
    const created = date;
    const updated = date;
    const name = `${year}${denomination}${date}`;
    const imageURL = URL.createObjectURL(this.imageRef.current.files[0]);

    this.setState({
      year,
      denomination,
      description,
      created,
      updated,
      name,
      imageURL
    });

    this.setState({ cropping: true });
  };

  uploadImage = async image => {
    this.setState({ cropping: false });

    const year = this.state.year;
    const denomination = this.state.denomination;
    const description = this.state.description;
    const created = this.state.created;
    const updated = this.state.updated;
    const name = this.state.name;

    const imageURL = await this.props.uploadSpecialImg(image, name);

    const coin = {
      year,
      denomination,
      description,
      imageURL,
      created,
      updated,
      name
    };

    this.props.addSpecialCoin(coin);
  };

  render() {
    if (this.state.cropping) {
      return (
        <CropSpecialCoin
          uploadImage={this.uploadImage}
          name={this.state.name}
          imageURL={this.state.imageURL}
        />
      );
    }

    return (
      <div className="special-coin-form-outer">
        <div className="toggle-coin-form">
          <button onClick={this.props.toggleForm}>
            {this.props.showForm ? "Hide Form" : "Add Coin"}
          </button>
        </div>
        <form
          className={`special-coin-form ${this.props.showForm ? "" : "hidden"}`}
          onSubmit={this.createCoin}
        >
          <input
            name="year"
            ref={this.yearRef}
            type="number"
            min="-9999"
            max="9999"
            placeholder="Year"
            required
          />
          <select name="denomination" ref={this.denominationRef} required>
            <option value="toonie">Toonie</option>
            <option value="loonie">Loonie</option>
            <option value="quarter">Quarter</option>
            <option value="dime">Dime</option>
            <option value="nickel">Nickel</option>
            <option value="penny">Penny</option>
            <option value="halfDollar">Half Dollar</option>
          </select>
          <textarea
            name="description"
            ref={this.descriptionRef}
            placeholder="Description"
            required
          />
          <input name="image" ref={this.imageRef} type="file" required />
          <button type="submit">Crop Image + Add Coin</button>
        </form>
      </div>
    );
  }
}
