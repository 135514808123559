import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import "normalize.css";
import "./index.css";
import "./App.css";
import "react-image-crop/dist/ReactCrop.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://277f09ec09f04fae81c2171ee7ca21f9@sentry.io/1316246"
});
LogRocket.init("io0jur/coins-dadabase");
Sentry.configureScope(scope => {
  scope.addEventProcessor(async event => {
    event.extra.sessionURL = LogRocket.sessionURL;
    return event;
  });
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
