import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUploadAlt,
  faTimesCircle,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

export default class CoinRow extends Component {
  handleUpload = event => {
    const img = event.currentTarget.files[0];
    const { index, startCrop } = this.props;
    const { name } = event.currentTarget;
    const url = URL.createObjectURL(img);

    startCrop({ key: index, name, img, url });
  };

  handleCellClick = (index, name) => {
    if (this.props.mode === "move") {
      this.props.movement(index, name);
    }
    if (this.props.mode === "remove") {
      this.props.removeItem(index, name);
    }
  };

  coinOut = (val, name) => {
    if (val === false && this.props.mode === "add") {
      return (
        <div className="empty-cell-icons">
          <label
            htmlFor={this.props.index + name}
            className="button"
            title="Upload Image"
          >
            <FontAwesomeIcon icon={faCloudUploadAlt} size="2x" />
            <input
              className="hidden-input"
              type="file"
              onChange={this.handleUpload}
              name={name}
              id={this.props.index + name}
            />
          </label>
          <FontAwesomeIcon
            icon={faTimesCircle}
            size="2x"
            onClick={() => {
              this.props.doesNotExist(this.props.index, name);
            }}
            className="hover-red"
            title="Does Not Exist"
          />
        </div>
      );
    } else if (val === "❌") {
      return (
        <FontAwesomeIcon icon={faTimes} size="2x" title="Does Not Exist" />
      );
    } else if (val === false && ["remove", "move"].includes(this.props.mode)) {
      return null;
    } else {
      return (
        <img
          src={val}
          className={`circle-border std-${name}`}
          alt="Pic of Coin"
        />
      );
    }
  };

  render() {
    const { toonie, loonie, quarter, dime, nickel, penny } = this.props.coin;
    return (
      <tr>
        <td className="side-td">{this.props.index}</td>
        <td
          className="coin-td std-toonie"
          onClick={() => {
            this.handleCellClick(this.props.index, "toonie");
          }}
        >
          {this.coinOut(toonie.main, "toonie")}
        </td>
        <td
          className="coin-td std-loonie"
          onClick={() => {
            this.handleCellClick(this.props.index, "loonie");
          }}
        >
          {this.coinOut(loonie.main, "loonie")}
        </td>
        <td
          className="coin-td std-quarter"
          onClick={() => {
            this.handleCellClick(this.props.index, "quarter");
          }}
        >
          {this.coinOut(quarter.main, "quarter")}
        </td>
        <td
          className="coin-td std-dime"
          onClick={() => {
            this.handleCellClick(this.props.index, "dime");
          }}
        >
          {this.coinOut(dime.main, "dime")}
        </td>
        <td
          className="coin-td std-nickel"
          onClick={() => {
            this.handleCellClick(this.props.index, "nickel");
          }}
        >
          {this.coinOut(nickel.main, "nickel")}
        </td>
        <td
          className="coin-td std-penny"
          onClick={() => {
            this.handleCellClick(this.props.index, "penny");
          }}
        >
          {this.coinOut(penny.main, "penny")}
        </td>
      </tr>
    );
  }
}
