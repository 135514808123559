import React, { Component } from "react";
import AddSpecialCoin from "./AddSpecialCoin";
import EditSpecialCoin from "./EditSpecialCoin";

export default class SpecialCoins extends Component {
  state = {
    coinNames: {
      toonie: "Toonie",
      loonie: "Loonie",
      quarter: "Quarter",
      dime: "Dime",
      nickel: "Nickel",
      penny: "Penny",
      halfDollar: "Half Dollar"
    },
    showForm: false,
    editCoin: null
  };

  toggleForm = () => {
    const showForm = !this.state.showForm;
    this.setState({ showForm });
  };

  editForm = () => {
    if (this.state.editCoin && !this.state.showForm) {
      return (
        <EditSpecialCoin
          editCoin={this.state.editCoin}
          uploadSpecialImg={this.props.uploadSpecialImg}
          updateSpecialCoin={this.props.updateSpecialCoin}
          removeSpecialCoin={this.props.removeSpecialCoin}
          finishEditing={this.finishEditing}
          special={this.props.special}
        />
      );
    }
    return null;
  };

  finishEditing = () => {
    this.setState({ editCoin: null });
  };

  handleClick = key => {
    this.setState({ editCoin: key });
    this.setState({ showForm: false });
  };

  render() {
    const special = Object.keys(this.props.special);
    return (
      <div
        className={`special-coin-page-wrapper ${
          this.state.showForm || this.state.editCoin ? "" : "hide-special-form"
        }`}
      >
        <div className="table-links">
          <button
            className="link special-coins"
            onClick={() => {
              this.props.setActivePage("standard");
            }}
          >
            <h4>Standard Coins</h4>
          </button>
          <h4>Special Coins</h4>
        </div>
        <AddSpecialCoin
          uploadSpecialImg={this.props.uploadSpecialImg}
          addSpecialCoin={this.props.addSpecialCoin}
          showForm={this.state.showForm}
          toggleForm={this.toggleForm}
        />
        {this.editForm()}
        <div
          className={`special-coin-wrapper ${
            special.length === 0 ? "hidden" : ""
          }`}
        >
          {special
            .sort(
              (a, b) => this.props.special[b].year - this.props.special[a].year
            )
            .map(key => {
              return (
                <div
                  className="special-coin-box"
                  key={key}
                  onClick={() => {
                    this.handleClick(key);
                  }}
                >
                  <h6>
                    {`${this.props.special[key].year} ${
                      this.state.coinNames[this.props.special[key].denomination]
                    }`}
                    {}
                  </h6>
                  <img
                    src={this.props.special[key].imageURL}
                    alt="Pic of Coin"
                    className={this.props.special[key].denomination}
                  />
                  <p>{this.props.special[key].description}</p>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
