import React, { Component } from "react";
import CoinRow from "./CoinRow";

class CoinTable extends Component {
  state = {
    stdChunks: {},
    activeStdChunk: 0,
    loading: true,
    mode: "add",
    startMove: null
  };

  componentWillMount() {
    this.setStdChunks();
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  removeItem = (index, name) => {
    if (this.props.standard[index][name].main === "❌") {
      this.props.mightExist(index, name);
    } else if (this.props.standard[index][name].main !== false) {
      this.props.rmStdImg(index, name);
    }
  };

  setStdChunks = () => {
    const dateRange = this.props.dateRange().sort((a, b) => a - b);
    let chunk = 0;
    const stdChunks = [];
    stdChunks[chunk] = { years: [], min: null, max: null };
    dateRange.forEach(year => {
      stdChunks[chunk].years.push(year);
      if ((year + 1) % 20 === 0) {
        stdChunks[chunk].min = Math.min(...stdChunks[chunk].years);
        stdChunks[chunk].max = Math.max(...stdChunks[chunk].years);
        chunk++;
        stdChunks[chunk] = { years: [], min: null, max: null };
      }
    });
    stdChunks[chunk].min = Math.min(...stdChunks[chunk].years);
    stdChunks[chunk].max = Math.max(...stdChunks[chunk].years);
    this.setState({ stdChunks });
  };

  setActiveStdChunk = index => {
    this.setState({ activeStdChunk: index });
  };

  movement = (index, name) => {
    if (
      this.state.startMove === null &&
      ![false, "❌"].includes(this.props.standard[index][name].main)
    ) {
      const startMove = { index, name };
      this.setState({ startMove });
    } else if (this.state.startMove !== null) {
      this.props.swapStandardCoins(this.state.startMove, { index, name });
      this.setState({ startMove: null });
    }
  };

  render() {
    const standard = Object.keys(this.props.standard);
    const stdChunks = this.state.stdChunks.sort((a, b) => b.min - a.min);
    const validYears = stdChunks[this.state.activeStdChunk].years;
    if (this.state.loading) {
      return <h4>Loading...</h4>;
    } else {
      return (
        <div className="table">
          <div className="table-links">
            <h4>Standard Coins</h4>
            <button
              className="link special-coins"
              onClick={() => {
                this.props.setActivePage("special");
              }}
            >
              <h4>Special Coins</h4>
            </button>
          </div>
          <div className="date-links">
            {stdChunks.map((chunk, index) => (
              <button
                key={index}
                className={
                  index === this.state.activeStdChunk
                    ? "link active-link"
                    : "link"
                }
                onClick={() => {
                  this.setActiveStdChunk(index);
                }}
              >
                {chunk.max}-{chunk.min}
              </button>
            ))}
          </div>
          <div className="action-buttons">
            <button
              className={`${this.state.mode === "add" ? "depressed" : ""}`}
              onClick={() => {
                const mode = "add";
                this.setState({ mode });
              }}
            >
              Add
            </button>
            <button
              className={`${this.state.mode === "remove" ? "depressed" : ""}`}
              onClick={() => {
                const mode = "remove";
                this.setState({ mode });
              }}
            >
              Remove
            </button>
            <button
              className={`${this.state.mode === "move" ? "depressed" : ""}`}
              onClick={() => {
                const mode = "move";
                this.setState({ mode });
              }}
            >
              Move
            </button>
          </div>
          <table className="standard-coins">
            <thead>
              <tr>
                <td />
                <td>Toonie</td>
                <td>Loonie</td>
                <td>Quarter</td>
                <td>Dime</td>
                <td>Nickel</td>
                <td>Penny</td>
              </tr>
            </thead>
            <tbody>
              {standard.sort((a, b) => b - a).map(key => {
                if (validYears.includes(parseInt(key))) {
                  return (
                    <CoinRow
                      key={key}
                      index={key}
                      coin={this.props.standard[key]}
                      addImage={this.props.addStdImg}
                      startCrop={this.props.startCrop}
                      doesNotExist={this.props.doesNotExist}
                      mode={this.state.mode}
                      movement={this.movement}
                      removeItem={this.removeItem}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </table>
        </div>
      );
    }
  }
}

export default CoinTable;
