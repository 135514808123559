import React, { Component } from "react";
import CropSpecialCoin from "./CropSpecialCoin";

export default class EditSpecialCoin extends Component {
  state = {
    cropping: false,
    year: null,
    denomination: null,
    description: null,
    image: null,
    created: null,
    updated: null,
    name: null,
    imageURL: null
  };

  yearRef = React.createRef();
  denominationRef = React.createRef();
  descriptionRef = React.createRef();
  imageRef = React.createRef();

  updateCoin = e => {
    e.preventDefault();

    const date = Date.now();

    const year = this.yearRef.current.value;
    const denomination = this.denominationRef.current.value;
    const description = this.descriptionRef.current.value;
    const { created, name } = this.props.special[this.props.editCoin];
    const updated = date;
    const imageURL = this.imageRef.current.files[0]
      ? URL.createObjectURL(this.imageRef.current.files[0])
      : this.props.special[this.props.editCoin].imageURL;

    this.setState({
      year,
      denomination,
      description,
      created,
      updated,
      name,
      imageURL
    });

    this.setState({ cropping: true });
  };

  uploadImage = async image => {
    this.setState({ cropping: false });

    const {
      year,
      denomination,
      description,
      created,
      updated,
      name
    } = this.state;

    const imageURL = await this.props.uploadSpecialImg(image, name);

    const coin = {
      year,
      denomination,
      description,
      imageURL,
      created,
      updated,
      name
    };

    this.props.updateSpecialCoin(coin);
    this.props.finishEditing();
  };

  deleteCoin = key => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      this.props.removeSpecialCoin(key);
      this.props.finishEditing();
    }
  };

  render() {
    if (this.state.cropping) {
      return (
        <CropSpecialCoin
          uploadImage={this.uploadImage}
          name={this.state.name}
          imageURL={this.state.imageURL}
        />
      );
    }

    const { denomination, description, year } = this.props.special[
      this.props.editCoin
    ];

    return (
      <div className="edit-coin-form-outer">
        <form
          className={`special-coin-form ${this.props.editCoin ? "" : "hidden"}`}
          onSubmit={this.updateCoin}
        >
          <input
            name="year"
            ref={this.yearRef}
            type="number"
            min="-9999"
            max="9999"
            placeholder="Year"
            defaultValue={year}
            required
          />
          <select
            defaultValue={denomination}
            name="denomination"
            ref={this.denominationRef}
            required
          >
            <option value="toonie">Toonie</option>
            <option value="loonie">Loonie</option>
            <option value="quarter">Quarter</option>
            <option value="dime">Dime</option>
            <option value="nickel">Nickel</option>
            <option value="penny">Penny</option>
            <option value="halfDollar">Half Dollar</option>
          </select>
          <textarea
            defaultValue={description}
            name="description"
            ref={this.descriptionRef}
            placeholder="Description"
            required
          />
          <input name="image" ref={this.imageRef} type="file" />
          <button type="submit">Crop Image + Update Coin</button>
          <button
            className="danger"
            onClick={() => {
              this.deleteCoin(this.props.editCoin);
            }}
          >
            Delete Coin
          </button>
        </form>
      </div>
    );
  }
}
