import React, { Component } from "react";
import firebase from "firebase";
import LogRocket from "logrocket";
import StandardCoins from "./StandardCoins";
import SpecialCoins from "./SpecialCoins";
import base, { firebaseApp } from "../base";
import StdImgCropper from "./StdImgCropper";

class App extends Component {
  state = {
    standard: {},
    special: {},
    username: "Anonymous",
    userEmail: null,
    selectedFile: null,
    uid: null,
    owner: null,
    loading: true,
    activePage: "standard"
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        await this.authHandler({ user });
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    });
  }

  authenticate = provider => {
    const authProvider = new firebase.auth[`${provider}AuthProvider`]();
    firebaseApp
      .auth()
      .signInWithPopup(authProvider)
      .then(this.authHandler);
  };

  authHandler = async authData => {
    const db = await base.fetch(authData.user.uid, { context: this });

    if (!db.owner) {
      await base.post(`${authData.user.uid}/owner`, {
        data: authData.user.uid
      });
    }

    const standard = { ...this.getStdYrs(), ...db.standard };

    await base.post(`${authData.user.uid}/standard`, {
      data: standard
    });

    await this.setState({
      username: authData.user.displayName,
      userEmail: authData.user.email,
      uid: authData.user.uid,
      owner: db.owner || authData.user.uid
    });

    LogRocket.identify(this.state.uid, {
      name: this.state.username,
      email: this.state.userEmail
    });

    this.ref = base.syncState(`/${this.state.uid}/standard`, {
      context: this,
      state: "standard"
    });
    this.ref = base.syncState(`/${this.state.uid}/special`, {
      context: this,
      state: "special"
    });
    this.ref = base.syncState(`/${this.state.uid}/activePage`, {
      context: this,
      state: "activePage"
    });
  };

  logout = async () => {
    await firebase.auth().signOut();
    this.setState({
      uid: null,
      owner: null,
      username: "Anonymous",
      userEmail: null
    });
    base.removeBinding(this.ref);
  };

  addStdImg = async imgObj => {
    const { key, name, finalImg } = imgObj;

    const storage = firebase.storage();
    const metaData = {
      contentType: finalImg.type
    };
    const snapshot = await storage
      .ref(`${this.state.uid}/images/${finalImg.name}`)
      .put(finalImg, metaData);

    const date = Date.now();

    const standard = { ...this.state.standard };
    const coin = standard[key];
    coin[name].main = await snapshot.ref.getDownloadURL();
    coin[name].name = finalImg.name;
    if (!coin[name].created) {
      coin[name].created = date;
    }
    coin[name].updated = date;
    standard[key] = coin;
    this.setState({ standard });
  };

  rmStdImg = async (key, name) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      const standard = { ...this.state.standard };
      const coin = standard[key];
      const storage = firebase.storage();
      await storage.ref(`${this.state.uid}/images/${coin[name].name}`).delete();
      coin[name].main = false;
      coin[name].name = null;
      const date = Date.now();
      coin[name].updated = date;
      this.setState({ standard });
    }
  };

  startCrop = imgObj => {
    this.setState({ selectedFile: imgObj });
  };

  finishCrop = () => {
    this.setState({ selectedFile: null });
  };

  doesNotExist = (key, name) => {
    if (window.confirm("Are you sure this coin does not exist?")) {
      const standard = { ...this.state.standard };
      const coin = standard[key];
      coin[name].main = "❌";
      coin[name].name = null;
      const date = Date.now();
      if (!coin[name].created) {
        coin[name].created = date;
      }
      coin[name].updated = date;
      this.setState({ standard });
    }
  };

  mightExist = (key, name) => {
    if (window.confirm("Are you sure this coin exists?")) {
      const standard = { ...this.state.standard };
      const coin = standard[key];
      coin[name].main = false;
      coin[name].name = null;
      const date = Date.now();
      coin[name].updated = date;
      this.setState({ standard });
    }
  };

  dateRange = () => {
    const earliest = 1900;
    const latest = new Date().getFullYear();
    const years = Array(latest - earliest + 1)
      .fill()
      .map((e, i) => (e = i + 1900));
    return years;
  };

  getStdYrs = () => {
    const earliest = 1900;
    const latest = new Date().getFullYear();
    const standard = {};
    const sample = { main: false, name: null, created: null, updated: null };
    for (let x = earliest; x <= latest; x++) {
      standard[x] = {
        toonie: { ...sample },
        loonie: { ...sample },
        quarter: { ...sample },
        dime: { ...sample },
        nickel: { ...sample },
        penny: { ...sample }
      };
    }
    return standard;
  };

  uploadSpecialImg = async (img, name) => {
    const storage = firebase.storage();
    const metaData = {
      contentType: img.type
    };
    const snapshot = await storage
      .ref(`${this.state.uid}/images/${name}`)
      .put(img, metaData);

    return await snapshot.ref.getDownloadURL();
  };

  setActivePage = pageName => {
    if (["special", "standard"].includes(pageName)) {
      this.setState({ activePage: pageName });
    }
  };

  addSpecialCoin = coinObj => {
    const special = { ...this.state.special };
    special[coinObj.name] = coinObj;
    this.setState({ special });
  };

  updateSpecialCoin = coinObj => {
    const special = { ...this.state.special };
    special[coinObj.name] = coinObj;
    this.setState({ special });
  };

  removeSpecialCoin = async key => {
    const special = { ...this.state.special };
    special[key] = null;
    this.setState({ special });
    await this.removeSpecialImage(key);
  };

  removeSpecialImage = async key => {
    const storage = firebase.storage();
    await storage.ref(`${this.state.uid}/images/${key}`).delete();
    return key;
  };

  swapStandardCoins = (startMove, endMove) => {
    const standard = { ...this.state.standard };
    const temp = {
      start: standard[startMove.index][startMove.name],
      end: standard[endMove.index][endMove.name]
    };
    if (["❌", false].includes(temp.end.main)) {
      standard[startMove.index][startMove.name] = {
        main: false,
        name: null,
        created: null,
        updated: null
      };
    } else {
      standard[startMove.index][startMove.name] = {
        ...temp.end
      };
    }
    standard[endMove.index][endMove.name] = {
      ...temp.start
    };
    this.setState({ standard });
  };

  renderOptions = () => {
    if (this.state.loading === true) {
      return <h4>Loading...</h4>;
    }

    if (this.state.activePage === "special") {
      return (
        <>
          <div className="main-button-group">
            <p>{this.state.userEmail}</p>
            <button onClick={this.logout}>Logout</button>
          </div>
          <SpecialCoins
            setActivePage={this.setActivePage}
            addSpecialCoin={this.addSpecialCoin}
            removeSpecialCoin={this.removeSpecialCoin}
            updateSpecialCoin={this.updateSpecialCoin}
            uploadSpecialImg={this.uploadSpecialImg}
            special={this.state.special}
          />
        </>
      );
    }

    if (this.state.uid === null) {
      return (
        <>
          <h4>Login to see your coins</h4>
          <button
            onClick={() => {
              this.authenticate("Google");
            }}
          >
            Login with Google
          </button>
        </>
      );
    }

    if (this.state.selectedFile === null) {
      return (
        <>
          <div className="main-button-group">
            <p>{this.state.userEmail}</p>
            <button onClick={this.logout}>Logout</button>
          </div>
          <div className="table-wrapper">
            <StandardCoins
              standard={this.state.standard}
              dateRange={this.dateRange}
              addStdImg={this.addStdImg}
              rmStdImg={this.rmStdImg}
              startCrop={this.startCrop}
              doesNotExist={this.doesNotExist}
              mightExist={this.mightExist}
              setActivePage={this.setActivePage}
              swapStandardCoins={this.swapStandardCoins}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="main-button-group">
            <p>{this.state.userEmail}</p>
            <button onClick={this.logout}>Logout</button>
          </div>
          <StdImgCropper
            selectedFile={this.state.selectedFile}
            addStdImg={this.addStdImg}
            finishCrop={this.finishCrop}
          />
        </>
      );
    }
  };

  render() {
    return (
      <div className="App">
        <div id="app-wrap" className="app-wrap app-transform">
          <h1 className="main">Coin Dadabase</h1>
          {this.renderOptions()}
        </div>
      </div>
    );
  }
}

export default App;
