import Rebase from "re-base";
import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyA-xA0fG-sW_V4XgsNvVlhkMCE_eR4QYXE",
  authDomain: "coins-dadabase.firebaseapp.com",
  databaseURL: "https://coins-dadabase.firebaseio.com",
  storageBucket: "coins-dadabase.appspot.com"
});

const base = Rebase.createClass(firebaseApp.database());

// This is a named export
export { firebaseApp };

// this is a default export
export default base;
