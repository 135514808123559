import React, { Component } from "react";
import ReactCrop from "react-image-crop";

export default class ImgCropper extends Component {
  state = {
    crop: {
      x: 0,
      y: 0,
      width: 100,
      aspect: 1 / 1
    },
    pixelCrop: null,
    previewImg: "",
    finalImg: null
  };

  showCropped = () => {
    const { selectedFile } = this.props;
    const { key, name, url } = selectedFile;
    const { pixelCrop } = this.state;

    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    const img = new Image();

    img.src = url;

    ctx.drawImage(
      img,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(file => {
        file.name = key + name + Date.now();
        resolve(file);
      }, "image/jpeg");
    });
  };

  previewImg = async () => {
    const finalImg = await this.showCropped();
    const previewImg = URL.createObjectURL(finalImg);
    this.setState({ previewImg, finalImg });
  };

  uploadImg = () => {
    const imgObj = {
      ...this.props.selectedFile,
      finalImg: this.state.finalImg
    };
    this.props.addStdImg(imgObj);
    this.props.finishCrop();
  };

  render() {
    return (
      <>
        <ReactCrop
          src={this.props.selectedFile.url}
          onChange={crop => {
            this.setState({ crop });
          }}
          onComplete={(crop, pixelCrop) => {
            this.setState({ pixelCrop });
            if (this.state.pixelCrop) this.previewImg();
          }}
          onImageLoaded={async (image, pixelCrop) => {
            await this.setState({ pixelCrop });
            this.previewImg();
          }}
          crop={this.state.crop}
        />
        <div className="preview-block">
          <img
            className="img-preview"
            src={this.state.previewImg}
            alt="Preview"
          />
          <button
            className="save-button"
            onClick={() => {
              this.uploadImg();
            }}
          >
            Save Image
          </button>
        </div>
      </>
    );
  }
}
